body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  padding-inline-start: 16px;
}

a {
  color: #666666;
}

a:hover {
  color: #1890ff;
}

* {
  font-family: 'Prompt', sans-serif;
}

.full-height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.topnav-item {
  padding: 13.5px 32px;
  color: rgba(112, 112, 112, 1);
  cursor: pointer;
  margin-bottom: 4px;
  position: relative;
}

.topnav-item:hover {
  color: rgba(28, 112, 176, 1);
  font-weight: 600;
}

.topnav-item:hover::after {
  display: block;
  content: '';
  position: absolute;
  background-color: rgba(28, 112, 176, 1);
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
}

.topnav-item.active {
  color: rgba(28, 112, 176, 1);
  font-weight: 600;
}

.topnav-item.active::after {
  display: block;
  content: '';
  position: absolute;
  background-color: rgba(28, 112, 176, 1);
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
}

.sidenav-item {
  color: rgba(112, 112, 112, 1);
  cursor: pointer;
  margin-bottom: 4px;
}

.sidenav-item:hover {
  color: rgba(112, 112, 112, 1);
  font-weight: 600;
}

.sidenav-item.active {
  color: rgba(28, 112, 176, 1);
  font-weight: 600;
}

.smooth-scroll {
  -webkit-overflow-scrolling: touch;
}

.text-wrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

tr.section {
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.02);
}

tr.section:hover > td {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.platform-switch.ant-switch {
  background-color: #4bc8ff;
}

.platform-switch.ant-switch.ant-switch-checked {
  background-color: #5cdd0b;
}

.linechart-tooltip {
  padding: 4px 8px;
}
